<template>
    <v-container fluid>
        <v-row justify="center">
            <v-col cols="12" v-if="error">
                <dashboard-error></dashboard-error>
            </v-col>
            <v-col cols="12" v-else>
                <div class="d-flex align-center">
                    <v-btn color="primary" @click="csvExport()" :loading="csvExporting">Export to CSV</v-btn>
                    <v-spacer></v-spacer>
                    <v-menu offset-y :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" class="mr-2">
                                Columns
                                <v-icon right>mdi-chevron-down</v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item-group v-model="selectedHeaders" multiple>
                                <v-list-item v-for="column in orderedHeaders" :key="column.column_id" :value="column">
                                    <template v-slot:default="{ active }">
                                        <v-list-item-action>
                                            <v-checkbox :input-value="active"></v-checkbox>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ column.text }}</v-list-item-title>
                                        </v-list-item-content>
                                    </template>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-menu>
                    <v-autocomplete
                        dense
                        solo
                        multiple
                        hide-details
                        chips
                        clearable
                        deletable-chips
                        return-object
                        item-text="name"
                        v-model="selectedFilters"
                        :items="filters"
                        label="Filters">
                        <template v-slot:item="{ item, on, attrs }">
                            <v-list-item two-line v-bind="attrs" v-on="on">
                                <template v-slot:default="{ active }">
                                    <v-list-item-action>
                                        <v-checkbox :input-value="active"></v-checkbox>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ item.name }}</v-list-item-title>
                                        <v-list-item-subtitle>
                                            {{ item.description }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </template>
                            </v-list-item>
                        </template>
                    </v-autocomplete>
                </div>
                <v-data-table
                    :headers="filteredHeaders"
                    :items="inventory"
                    :item-key="oid ? 'sid' : 'oid'"
                    :options.sync="options"
                    :server-items-length="inventorySize"
                    :footer-props="{ 'items-per-page-options': itemsPerPageOptions }"
                    :loading="fetchingInitInventory || fetchingInventory || fetchingInventorySize"
                    :expanded.sync="expanded"
                    @click:row="clickRow"
                    show-expand
                    class="inventory-table">
                    <template v-slot:top="{ options, pagination, updateOptions }">
                        <v-data-footer
                            :pagination="pagination"
                            :options="options"
                            @update:options="updateOptions"
                            :itemsPerPageOptions="itemsPerPageOptions"
                            items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                            class="no-border" />
                    </template>
                    <template v-slot:expanded-item="{ headers }">
                        <td :colspan="headers.length">
                            <!-- Organization level -->
                            <template v-if="oid">
                                <v-container fluid>
                                    <v-row>
                                        <v-col>
                                            <v-card flat color="grey lighten-3">
                                                <v-card-title class="text-body-1"><b>Space admins</b></v-card-title>
                                                <v-card-text>
                                                    <dashboard-loader v-if="fetchingOrgLevelData"></dashboard-loader>
                                                    <template v-else>
                                                        <template v-if="spaceAdmins.length > 0 && !$isError(spaceAdmins)">
                                                            <div v-for="(admin, i) in spaceAdmins" :key="i" class="py-1">
                                                                {{ admin.full_name }}:
                                                                <v-chip class="mx-2">
                                                                    {{ admin.email }}
                                                                </v-chip>
                                                                <copy-to-clipboard :textToCopy="admin.email" :iconButton="true"></copy-to-clipboard>
                                                            </div>
                                                        </template>
                                                        <dashboard-empty v-if="spaceAdmins.length === 0"></dashboard-empty>
                                                        <dashboard-error v-if="$isError(spaceAdmins)"></dashboard-error>
                                                    </template>
                                                </v-card-text>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </template>
                            <!-- RP level -->
                            <template v-else>
                                <v-container fluid>
                                    <v-row>
                                        <v-col>
                                            <v-card flat color="grey lighten-3">
                                                <v-card-title class="text-body-1"><b>Managers</b></v-card-title>
                                                <v-card-text>
                                                    <dashboard-loader v-if="fetchingRPLevelData"></dashboard-loader>
                                                    <template v-else>
                                                        <template v-if="orgManagers.length > 0 && !$isError(orgManagers)">
                                                            <div v-for="(manager, i) in orgManagers" :key="i" class="py-1">
                                                                {{ manager.full_name }}:
                                                                <v-chip class="mx-2">
                                                                    {{ manager.email }}
                                                                </v-chip>
                                                                <copy-to-clipboard :textToCopy="manager.email" :iconButton="true"></copy-to-clipboard>
                                                            </div>
                                                        </template>
                                                        <dashboard-empty v-if="orgManagers.length === 0"></dashboard-empty>
                                                        <dashboard-error v-if="$isError(orgManagers)"></dashboard-error>
                                                    </template>
                                                </v-card-text>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </template>
                        </td>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-btn v-if="item.space_admins === 0" @click.stop="deleteSpace(item.sid)" icon :loading="deletingSpace === item.sid">
                            <v-icon small>delete</v-icon>
                        </v-btn>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import dashboard from '@/mixins/dashboard'
import { orderBy, pick } from 'lodash'
import jsonexport from 'jsonexport'
import { bytesToGB } from '@/utils'
const CopyToClipboard = () => import('@/components/CopyToClipboard.vue')

export default {
    name: 'ResourcePoolInventory',
    mixins: [dashboard],
    components: { CopyToClipboard },
    data() {
        return {
            search: '',
            headers: [],
            selectedHeaders: [],
            inventory: [],
            inventorySize: 0,
            fetchingInventory: false,
            fetchingInventorySize: false,
            fetchingInitInventory: false,

            fetchingRPLevelData: false,
            fetchingOrgLevelData: false,
            options: null,
            itemsPerPageOptions: [],
            filters: [],
            selectedFilters: [],
            error: null,
            expanded: [],
            orgManagers: [],
            spaceAdmins: [],
            csvExporting: false,
            deletingSpace: null
        }
    },
    computed: {
        filteredHeaders() {
            return this.headers.filter(header => this.selectedHeaders.includes(header))
        },
        orderedHeaders() {
            return orderBy(this.headers, 'value')
        },
        apiUrl() {
            if (!this.rpid) return ''
            let url = `/resource/${this.rpid}`
            if (this.oid) url += `/org/${this.oid}`
            return url
        }
    },
    watch: {
        options: {
            handler(options) {
                this.fetchInventory()
            },
            deep: true
        },
        selectedHeaders() {
            if (this.oid) {
                this.filteredHeaders.push({ text: 'Actions', value: 'actions' })
            }
            this.filteredHeaders.push({ text: '', value: 'data-table-expand', align: 'right' })
        },
        selectedFilters() {
            this.selectedFilters.forEach(filter => {
                filter.column_requirements.forEach(columnId => {
                    if (!this.selectedHeaders.some(header => header.value === columnId)) {
                        this.selectedHeaders.push(this.headers.find(header => header.value === columnId))
                    }
                })
            })
            this.resetPagination()
            this.fetchInventorySize()
        },
        apiUrl: {
            handler() {
                this.initInventory()
            },
            immediate: true
        }
    },
    methods: {
        formatInventoryData(inventoryData) {
            return inventoryData.map(row => {
                this.headers.forEach(header => {
                    switch (header.format) {
                        case 'datetime':
                            row[header.value] = this.$options.filters.dateTimeToHuman(row[header.value])
                            break
                        case 'percentage':
                            row[header.value] = this.percentage(row[header.value])
                            break
                        case 'byte':
                            row[header.value] = `${bytesToGB(row[header.value])} GB`
                            break
                        case 'seconds':
                            if (row[header.value] !== null) {
                                row[header.value] = `${row[header.value].toLocaleString()}s`
                            }
                            break
                    }
                })
                return row
            })
        },
        formatInventoryDataForCSV(inventoryData) {
            return inventoryData.map(row => {
                this.headers.forEach(header => {
                    switch (header.format) {
                        case 'datetime':
                            row[header.value] = this.$options.filters.dateTimeToHuman(row[header.value])
                            break
                        case 'byte':
                            row[header.value] = bytesToGB(row[header.value])
                            break
                    }
                })
                return row
            })
        },
        percentage(number) {
            if (typeof number !== 'number') return
            const multiplied = number * 100
            if (number > 0 && multiplied < 0.01) {
                return `0.01%`
            }
            return `${multiplied.toFixed(2)}%`
        },
        downloadBlob(content, filename, contentType) {
            const blob = new Blob([content], { type: contentType })
            const url = URL.createObjectURL(blob)

            // Create a link to download it
            const pom = document.createElement('a')
            pom.href = url
            pom.setAttribute('download', filename)
            pom.click()
        },
        queryParams({ all = false } = {}) {
            const { sortBy, sortDesc, page, itemsPerPage } = this.options
            const query = {
                page: all ? 1 : page,
                per_page: all ? -1 : itemsPerPage
            }
            if (sortBy.length > 0) {
                query.sort_by = sortBy
                query.sort_desc = sortDesc
            }
            const queryParams = new URLSearchParams(query)
            if (this.selectedFilters.length > 0) {
                this.selectedFilters.forEach(filter => {
                    queryParams.append('filter', filter.filter_id)
                })
            }
            return queryParams.toString()
        },
        async csvExport() {
            this.csvExporting = true
            try {
                const { data } = await this.$axios.get(`${this.apiUrl}/inventory?${this.queryParams({ all: true })}`)
                this.formatInventoryDataForCSV(data)
                const filteredHeaderIds = this.filteredHeaders.map(header => header.value)
                const csvData = data.map(row => {
                    return pick(row, filteredHeaderIds)
                })
                // Use human readable titles for the columns:
                csvData.forEach(row => {
                    for (const key in row) {
                        const header = this.filteredHeaders.find(header => header.value === key)
                        let title = ''
                        switch (header.format) {
                            case 'byte':
                                title = header.text + ' [GB]'
                                break
                            case 'seconds':
                                title = header.text + ' [seconds]'
                                break
                            default:
                                title = header.text
                                break
                        }
                        delete Object.assign(row, { [title]: row[key] })[key]
                    }
                })

                const self = this
                jsonexport(csvData, {}, function (err, csv) {
                    if (err) return
                    self.downloadBlob(csv, 'export.csv', 'text/csv;charset=utf-8;')
                })
            } catch (error) {
                this.$store.dispatch('showSnackBar', {
                    snackBarText: 'Failed to export CSV',
                    snackBarTimeout: 5000,
                    snackBarIcon: 'error'
                })
            }
            this.csvExporting = false
        },
        async deleteSpace(sid) {
            this.deletingSpace = sid
            try {
                await this.$axios.delete(`/resource/${this.rpid}/space/${sid}`)
                this.fetchInventorySize()
            } catch (error) {
                this.$store.dispatch('showSnackBar', {
                    snackBarText: 'Failed to delete space.',
                    snackBarTimeout: 5000,
                    snackBarIcon: 'error'
                })
            }
            this.deletingSpace = null
        },
        clickRow(item, event) {
            if (event.isExpanded) {
                this.expanded = []
            } else {
                this.expanded = []
                this.expanded.push(item)
                // Org level or space level
                if (this.oid) {
                    this.fetchOrgLevelData(item.sid)
                } else {
                    this.fetchRPLevelData(item.oid)
                }
            }
        },
        async fetchRPLevelData(oid) {
            this.fetchingRPLevelData = true
            try {
                const { data } = await this.$axios.get(`/resource/${this.rpid}/org/${oid}/contacts`)
                this.orgManagers = data
            } catch (error) {
                this.orgManagers = error
            }
            this.fetchingRPLevelData = false
        },
        async fetchOrgLevelData(sid) {
            this.fetchingOrgLevelData = true
            try {
                const { data } = await this.$axios.get(`/resource/${this.rpid}/space/${sid}/contacts`)
                this.spaceAdmins = data
            } catch (error) {
                this.spaceAdmins = error
            }
            this.fetchingOrgLevelData = false
        },
        async initInventory() {
            this.fetchingInitInventory = true
            try {
                const { data } = await this.$axios.get(`${this.apiUrl}/inventory?metadata=true&init=true`)
                this.inventorySize = data.count
                this.itemsPerPageOptions = data.items_per_page
                this.itemsPerPageOptions.push(-1)
                this.filters = data.filters

                this.options = {
                    page: 1,
                    itemsPerPage: this.itemsPerPageOptions[0],
                    sortBy: [],
                    sortDesc: []
                }

                this.headers = data.columns.map(column => {
                    return { text: column.name, value: column.column_id, visible: column.visible, format: column.format }
                })

                this.selectedHeaders = this.headers.filter(header => header.visible)
            } catch (error) {
                this.error = error
            }
            this.fetchingInitInventory = false
        },
        async fetchInventorySize() {
            this.fetchingInventorySize = true
            try {
                const { data } = await this.$axios.get(`${this.apiUrl}/inventory?metadata=true&${this.queryParams()}`)
                this.inventorySize = data.count
                this.fetchInventory()
            } catch (error) {
                this.error = error
            }
            this.fetchingInventorySize = false
        },
        async fetchInventory() {
            this.fetchingInventory = true
            try {
                const { data } = await this.$axios.get(`${this.apiUrl}/inventory?${this.queryParams()}`)
                this.inventory = this.formatInventoryData(data)
            } catch (error) {
                this.error = error
            } finally {
                this.fetchingInventory = false
            }
        },
        resetPagination() {
            this.options.page = 1
            this.options.itemsPerPage = this.itemsPerPageOptions[0]
            this.options.sortBy = []
            this.options.sortDesc = []
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/sass/dashboard.scss';
::v-deep .inventory-table {
    tr:not(.v-data-table__expanded__content) {
        cursor: pointer;
    }
}
</style>
